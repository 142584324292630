<template>
  <div>
    <b-tabs class="my-3" pills>
      <template #tabs-end>
        <b-nav-item
          v-for="tab in tabs"
          :key="tab.route"
          :to="{ name: tab.route }"
          :active="tab.active"
        >
          {{ tab.label }}
        </b-nav-item>
      </template>
    </b-tabs>
    <router-view />
  </div>
</template>

<script>
export default {
  computed: {
    tabs() {
      const tabs = [
        { route: 'product-orders-pending', label: 'Pending' },
        { route: 'product-orders-ordered', label: 'Ordered' },
      ];
      return tabs.map((tab) => ({
        ...tab,
        active: this.$route.name == tab.route,
      }));
    },
  },
};
</script>
